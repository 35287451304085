<div>
  <div>
    <div style="text-align:left;font-size: 1.3rem;">Benefit Document Checklist:</div>
    Please provide us with the below documents and information via ASANA.
    <ul>
      <li>Employee Handbook</li>
      <li>Benefit Rate Sheet: please provide the exact biweekly or monthly amount the employee and
        employer pays for each benefit plan (medical, dental, vision, voluntary benefits,
        life/ disability, etc).
      </li>
      <li>Documentation of any special arrangements (i.e. employees who are not paying for
        insurance, etc)</li>
      <li>Summary of Benefits and Coverage (SBCs) or Summary of Benefits for each benefit plan
        offered.</li>
      <li>Most recent invoice for each benefit plan.</li>
      <li>Latest payroll register that shows deductions that are currently being taken.</li>
      <li>401k Summary Plan Description and Adoption Agreement</li>
      <li>Documentation for anyone on COBRA at time of partnership.</li>
      <li>Documentation for anyone with an outstanding 401k Loan at time of partnership.</li>
      <li>If over 50 Employees: claim experience information (e.g., monthly claims, monthly
        enrollment by tier, monthly premiums, and large claim information for the past two years)</li>
    </ul>
  </div>
  <div>
    <div style="text-align:left;font-size: 1.3rem;">Notes for Seller</div>
    <ul>
      <li>You are responsible for closing of 401k plan post close. If there are any outstanding loans at the time of partnership, please work with the employees directly to handle.</li>
      <li>You are responsible for reporting of any 3rd party sick pay tax requirements for STD, LTD, etc after benefit effective date.</li>
      <li>You are responsible for terminating your ERISA Bond policy after benefit effective date.</li>
      <li>Employees will be eligible for our benefits first of the month after 30 days from partnership. Your existing plans will need to remain in effect until then. All employees are eligible for the 401k plan after 6 months of service.</li>
    </ul>
  </div>

</div>
