import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EditprojectService } from './editproject.service';
import { GlobalConstants } from '../../global-constants';

@Component({
  selector: 'app-editproject',
  templateUrl: './editproject.component.html',
  styleUrls: ['./editproject.component.css']
})
export class EditprojectComponent implements OnInit {

  projectId: string;
  onBoardingProject: any;
  projectName: String;
  projectDescription: String;
  projectOwner: String;
  processingStatus: String;
  createdOn: Date;
  lastProcessedOn: Date;
  showUserDialogFlag: boolean = false;
  editUserFlag: boolean = false;
  users: any = [];
  userActivities: any = [];
  actionTitle: String;
  formStatusResp: any;
  isErrorResponse: boolean = false;
  errorMessage: String;

  selectedUser = {
    name: '',
    userName: '',
    password: '',
    projectId: '',
    role: '',
    enabled: false,
    retypePassword: ''
  };


  formEditPermission = {
    userId: '',
    projectId: '',
    enable: true
  };

  spinnerModalDisplay = "none";
  pages: number[] = [1];
  currentPage: number = 1;
  ITEMS_PER_PAGE= 20;
  constructor(private route: ActivatedRoute, private router: Router, private editProjectService: EditprojectService) { }

  ngOnInit() {
    this.projectId = this.route.snapshot.queryParamMap.get('id');
    this.showSpinner()
    this.getProjectById(this.projectId);
    this.getUsersByProjectId();
    this.getFormStatusByProjectId();
    this.getUserActivityProjectById();
  }

  showSpinner() {
    this.spinnerModalDisplay = "block";
  }

  hideSpinner() {
    this.spinnerModalDisplay = "none";
  }

  getProjectById(id:string) {
    this.editProjectService.getProjectById(this.projectId)
      .subscribe(
        data => {
          this.onBoardingProject = data;
          this.projectName = this.onBoardingProject.projectName;
          this.projectDescription = this.onBoardingProject.description;
          this.projectOwner = this.onBoardingProject.owner;
          this.processingStatus = this.onBoardingProject.processingStatus;
          this.createdOn = this.onBoardingProject.createdOn;
          this.lastProcessedOn = this.onBoardingProject.updatedOn;
          this.hideSpinner();
        },
        error => {
          console.log(error);
          this.hideSpinner();
        });
  }

  getFormStatusByProjectId() {
    this.editProjectService.getFormStatusByProjectId(this.projectId)
      .subscribe(
        data => {
          this.formStatusResp = data;
        },
        error => {
          console.log(error);
        });
  }

  getUsersByProjectId() {
    this.editProjectService.getUsersByProjectId(this.projectId)
      .subscribe(
        data => {
          this.users = data;
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  getUserActivityProjectById() {
    this.showSpinner();
    this.editProjectService.getUserActivityProjectById(this.projectId)
      .subscribe(
        data => {
          this.userActivities = data;
          let max_page = Math.ceil((data as []).length / this.ITEMS_PER_PAGE)
          this.pages = [1]
          for (let i = 2; i <=max_page; i++) {
            this.pages.push(i)
          }
          console.log(this.pages)
          this.currentPage = 1;
          this.hideSpinner();
          //console.log(data);
        },
        error => {
          this.hideSpinner();
          console.log(error);
        });
  }

  enableFormEdit(){
    this.formEditPermission.projectId = this.projectId;
    this.formEditPermission.enable = true;
    this.formEditPermission.userId = sessionStorage.getItem(GlobalConstants.AUTH_LOGGED_IN_USER);
    this.editProjectService.enableFormEdit(this.formEditPermission)
      .subscribe(
        response => {
          this.getFormStatusByProjectId();
        },
        error => {
          console.log(error);
        });
  }

  showAddUserDialog(){
    this.actionTitle = "Add new user.";
    this.selectedUser.name = '';
    this.selectedUser.userName = '';
    this.selectedUser.password = '';
    this.selectedUser.projectId = '';
    this.selectedUser.role = '';
    this.selectedUser.name = '';
    this.selectedUser.enabled = false;
    this.showUserDialogFlag = true;
  }

  cancelUserDialog(){
    this.actionTitle = '';
    this.selectedUser.name = '';
    this.selectedUser.userName = '';
    this.selectedUser.password = '';
    this.selectedUser.retypePassword = '';
    this.selectedUser.projectId = '';
    this.selectedUser.role = '';
    this.selectedUser.name = '';
    this.selectedUser.enabled = false;
    this.showUserDialogFlag=false;
    this.editUserFlag = false;
  }

  showChangePasswordDialog(user){
    this.actionTitle = "Change Password for "+user.name;
    this.editUserFlag = true;
    this.selectedUser.userName = user.userName;
    this.selectedUser.password = user.password;
    this.selectedUser.role = user.role;
    this.selectedUser.name = user.name;
    this.selectedUser.enabled = user.enabled;
    this.showUserDialogFlag = true;
  }

  saveUserAction(){
    this.isErrorResponse = false;
    const data = {userName: this.selectedUser.userName,
                  password: this.selectedUser.password,
                  name: this.selectedUser.name,
                  role: 'USER',
                  projectId: this.projectId,
                  enabled: true
                  };
    if(this.editUserFlag){
      this.editProjectService.changeUserPassword(data)
        .subscribe(
          response => {
            this.getUsersByProjectId();
            this.cancelUserDialog();
          },
          error => {
            console.log(error);
            this.isErrorResponse = true;
            this.showUserDialogFlag = true;
            this.errorMessage = error.statusText || error.message;
          });
    }else{
      this.editProjectService.addUser(data)
        .subscribe(
          response => {
            this.getUsersByProjectId();
            this.cancelUserDialog();
          },
          error => {
            console.log(error);
            this.isErrorResponse = true;
            this.errorMessage = error.statusText || error.message;
            this.showUserDialogFlag = true;
          });
    }
  }

  deleteUserAction(user){
    this.isErrorResponse = false;
    this.editProjectService.deleteUser(user.id)
      .subscribe(
        response => {
          this.getUsersByProjectId();
        },
        error => {
          console.log(error);
          this.isErrorResponse = true;
          this.showUserDialogFlag = true;
          this.errorMessage = error.statusText || error.message || error.message;
        });
  }

  showDueDiligenceForms(){
    this.router.navigate(['/duediligenceform'], { queryParams: { onBoardingProjectId: this.projectId } });
  }

  scanProject(){
    this.isErrorResponse = false;
    this.showSpinner();
    this.editProjectService.scanProject(this.projectId)
      .subscribe(
        data => {
          this.hideSpinner();
          this.getUserActivityProjectById();
          //console.log(data);
        },
        error => {
          console.log(error);
          debugger;
          this.hideSpinner();
          this.isErrorResponse = true;
          this.errorMessage = error.statusText || error.message;
        });
  }

  asanaLink() {
    if(this.onBoardingProject) {
      return `https://app.asana.com/0/${this.onBoardingProject.externalProjectId}/${this.onBoardingProject.externalProjectId}`;
    }
    return "";
  }

  pageOf(userActivities: any[], currentPage: number): any[] {
    return userActivities.slice(this.ITEMS_PER_PAGE*(currentPage-1), this.ITEMS_PER_PAGE*currentPage);


  }

  recreateProject(onBoardingProject:any){
    this.showSpinner();
    this.editProjectService.recreateProjectById(onBoardingProject.id)
      .subscribe(
        data => {
          this.hideSpinner();
          this.getProjectById(onBoardingProject.id);
        },
        error => {
          this.hideSpinner();
          console.log(error);
        });
  }
}
